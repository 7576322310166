<template>
  <div class="navigate-bar">
    <div
      v-for="(item, index) in value"
      :key="index"
      class="navi-item"
      @click="jumpTo(item)"
    >
      <div class="navi-inner">
        <van-image width="32" height="32" :src="item.picUrl" fit="cover" />
        <div>{{ item.navigationName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigateBar",
  props: {
    static: {
      default: false,
    },
    value: {
      required: true,
      default() {
        return [];
      },
    },
    shopId: {
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    jumpTo(item) {
      if (this.static) {
        return;
      } else {
        if (item.navigationType === "1") {
          this.$router.push({
            path: "makerGoodsDetail",
            query: { id: item.storeProductInfoDTO.id },
          });
        } else {
          this.$router.push({
            path: "makerClassification",
            query: {
              shopId: this.shopId,
              categoryId: item.filedId,
              categoryName: item.navigationTypeName,
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.navigate-bar {
  margin: 5px 0;
  background-color: #fff;
  display: flex;
  overflow-y: auto;
  .navi-item {
    flex: 1;
    min-width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    .navi-inner {
      padding: 12px 0;
      text-align: center;
    }
  }
}
</style>