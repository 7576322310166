<template>
  <div class="advertise-slide-big">
    <div
      class="slide-item"
      v-for="(item, index) in value"
      :key="index"
      @click="jumpTo(item)"
    >
      <van-image
        width="37vw"
        height="27.5vw"
        :src="item.picUrl"
        style="border-radius: 5px; overflow: hidden"
        fit="cover"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "advertiseSlideBig",
  props: {
    static: {
      default: false,
    },
    value: {
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    jumpTo(item) {
      if (this.static) {
        return;
      } else {
        if (item.advertType === "1") {
          this.$router.push({
            path: "makerGoodsDetail",
            query: { id: item.storeProductInfoDTO.id },
          });
        } else {
          this.$router.push({
            path: "makerClassification",
            query: {
              shopId: this.shopId,
              categoryId: item.filedId,
              categoryName: item.navigationTypeName,
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.advertise-slide-big {
  margin: 1vw;
  padding: 2vw;
  width: 94vw;
  background-color: #fff;
  border-radius: 5px;
  overflow: auto;
  display: flex;
  .slide-item {
    margin-right: 2vw;
  }
}
</style>