<template>
  <div class="notice-bar">
    <van-notice-bar
      color="#999"
      background="#fff"
      left-icon="volume-o"
      :text="value[0].words"
    />
  </div>
</template>

<script>
export default {
  name: "noticeBar",
  props: {
    value: {
      required: true,
      default() {
        return [{ words: "" }];
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.notice-bar {
  margin: 5px 0;
  /deep/ .van-notice-bar__left-icon {
    color: #ff5200;
    font-weight: bold;
  }
}
</style>