<template>
  <div class="top-small">
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
      <van-image
        width="100%"
        height="100%"
        :src="value.signUrl ? value.signUrl : bgi"
        style="margin: 0"
        fit="cover"
      />
    </div>
    <div class="header-left">
      <van-image
        width="14vw"
        height="  14vw"
        :src="value.logoUrl ? value.logoUrl : src"
        fit="cover"
      />
      <div class="name">
        <div class="title">{{ value.storeName }}</div>
        <div class="desc">{{ value.storeDescribe }}</div>
      </div>
    </div>
    <div class="header-right">
      <div
        style="padding-right: 16px"
        @click="saveCollect"
        v-if="!value.collectId"
      >
        <div style="font-size: 20px; line-height: 20px">
          <van-icon name="star-o" />
        </div>
        <div style="font-size: 12px">收藏</div>
      </div>

      <div style="padding-right: 16px" v-else @click="cancelCollect">
        <div style="font-size: 20px; line-height: 20px">
          <van-icon name="star" />
        </div>
        <div style="font-size: 12px">已收藏</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topSmall",
  components: {},
  props: {
    static: {
      default: false,
    },
    // bgi: {
    //   default: require("@/assets/fitment/default-bgc.png"),
    // },
    // src: {
    //   default: require("@/assets/fitment/default.png"),
    // },
    value: {
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      bgi: require("@/assets/fitment/default-bgc.png"),
      src: require("@/assets/fitment/default.png"),
    };
  },
  methods: {
    saveCollect() {
      this.static ? null : this.$emit("saveCollect");
    },
    cancelCollect() {
      this.static ? null : this.$emit("cancelCollect");
    },
  },
};
</script>

<style lang="less" scoped>
.top-small {
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: relative;
  width: 100vw;
  height: 21vw;
  .header-left {
    display: flex;
    padding: 16px;
    z-index: 5;
    .van-image {
      margin-right: 10px;
      border-radius: 5px;
    }
    .name {
      .title {
        font-weight: 500;
        margin-bottom: 2px;
      }
      .desc {
        background-color: #f1f1f1;
        color: #333;
        height: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        padding: 0 10px;
      }
    }
  }
  .header-right {
    z-index: 5;
    text-align: center;
    width: 60px;
  }
}
</style>