<template>
  <div class="advertise-big">
    <van-image
      width="98vw"
      height="130.5vw"
      :src="value[0].picUrl"
      fit="cover"
      style="border-radius: 5px; overflow: hidden"
       @click="jumpTo(value[0])"
    />
  </div>
</template>

<script>
export default {
  name: "advertiseBig",
  props: {
    static: {
      default: false,
    },
    value: {
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    jumpTo(item) {
      if (this.static) {
        return;
      } else {
        if (item.advertType === "1") {
          this.$router.push({
            path: "makerGoodsDetail",
            query: { id: item.storeProductInfoDTO.id },
          });
        } else {
          this.$router.push({
            path: "makerClassification",
            query: {
              shopId: this.shopId,
              categoryId: item.filedId,
              categoryName: item.navigationTypeName,
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.advertise-big {
  margin: 1vw;
  width: 98vw;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}
</style>