<template>
  <div class="header-big">
    <div class="header-top">
      <van-image
        width="100%"
        height="100%"
        :src="value.signUrl ? value.signUrl : bgi"
        style="margin: 0"
        fit="cover"
      />
    </div>
    <van-image
      width="22vw"
      height="22vw"
      :src="value.logoUrl ? value.logoUrl : src"
      fit="cover"
    />
    <div class="header-bottom">
      <div class="bottom-left">
        <div class="title">{{ value.storeName }}</div>
        <div class="desc">{{ value.storeDescribe }}</div>
      </div>

      <div class="bottom-right" @click="saveCollect" v-if="!value.collectId">
        <div style="font-size: 20px; line-height: 20px">
          <van-icon name="star-o" />
        </div>
        <div style="font-size: 12px">收藏</div>
      </div>

      <div class="bottom-right" v-else @click="cancelCollect">
        <div style="font-size: 20px; line-height: 20px">
          <van-icon name="star" color="#ff5200" />
        </div>
        <div style="font-size: 12px">已收藏</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topBig",
  components: {},
  props: {
    static: {
      default: false,
    },
    // bgi: {
    //   default: require("@/assets/fitment/default-bgc.png"),
    // },
    // src: {
    //   default: require("@/assets/fitment/default.png"),
    // },
    value: {
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      bgi: require("@/assets/fitment/default-bgc.png"),
      src: require("@/assets/fitment/default.png"),
    };
  },
  methods: {
    saveCollect() {
      this.static ? null : this.$emit("saveCollect");
    },
    cancelCollect() {
      this.static ? null : this.$emit("cancelCollect");
    },
  },
};
</script>

<style lang="less" scoped>
.header-big {
  background-color: #fff;
  position: relative;
  width: 100vw;
  height: 41.5vw;
  .header-top {
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 21vw;
  }
  .van-image {
    margin: 14px 0 0 14px;
    border-radius: 5px;
  }
  .header-bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    .bottom-left {
      .title {
        color: #333;
        font-weight: 500;
        margin-bottom: 2px;
      }
      .desc {
        background-color: #f1f1f1;
        color: #333;
        height: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        padding: 0 10px;
      }
    }
    .bottom-right {
      text-align: center;
      width: 40px;
    }
  }
}
</style>