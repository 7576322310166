<template>
  <div class="advertise-two">
    <van-image
      v-for="(item, index) in value"
      :key="index"
      width="46vw"
      height="40vw"
      :src="item.picUrl"
      @click="jumpTo(item)"
      fit="cover"
    />
  </div>
</template>

<script>
export default {
  name: "advertiseTwo",
  props: {
    static: {
      default: false,
    },
    value: {
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    jumpTo(item) {
      if (this.static) {
        return;
      } else {
        if (item.advertType === "1") {
          this.$router.push({
            path: "makerGoodsDetail",
            query: { id: item.storeProductInfoDTO.id },
          });
        } else {
          this.$router.push({
            path: "makerClassification",
            query: {
              shopId: this.shopId,
              categoryId: item.filedId,
              categoryName: item.navigationTypeName,
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.advertise-two {
  margin: 1vw;
  padding: 2vw;
  width: 94vw;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  .van-image {
    border-radius: 5px;
    overflow: hidden;
    margin-right: 2vw;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}
</style>